<template>
  <header>
    <div class="header">
      <RssNews />
    </div>
  </header>
</template>

<script>
  import RssNews from '../components/RssNews.vue';

  export default{
    components: {
      RssNews
    }
  }
</script>

<style scoped>
  header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 150px;
    z-index: 10;
  }
</style>