<template>
  <div class="main_nav">

    <div class="main_logo">
      <img :src="mainLogo" alt="mainLogo">
    </div>

    <nav>

      <RouterLink to="/home" class="main_nav-item_wrapper">
        <div class="main_nav-item main_nav_home">
          <HomeIcon />
          <p>Home</p>
        </div>
      </RouterLink>

      <RouterLink to="/blog" class="main_nav-item_wrapper" :class="{'router-link-exact-active': blogRoute, 'router-link-active': blogRoute}">
        <div class="main_nav-item main_nav_blog">
          <FileIcon />
          <p>Ultime da PitStop</p>
        </div>
      </RouterLink>

      <RouterLink to="/simulator" class="main_nav-item_wrapper">
        <div class="main_nav-item main_nav_simulator">
          <SimulatoreIcon />
          <p>Gioca con il Simulatore</p>
        </div>
      </RouterLink>

      <RouterLink to="/quiz" class="main_nav-item_wrapper" :class="{'router-link-exact-active': quizRoute, 'router-link-active': quizRoute}">
        <div class="main_nav-item main_nav_quiz">
          <GameIcon />
          <p>Scopri quanto ne sai</p>
        </div>
      </RouterLink>

      <RouterLink to="/map" class="main_nav-item_wrapper">
        <div class="main_nav-item main_nav_map">
          <MapIcon />
          <p>PitStop points</p>
        </div>
      </RouterLink>

      <RouterLink to="/news" class="main_nav-item_wrapper" :class="{'router-link-exact-active': newsRoute, 'router-link-active': newsRoute}">
        <div class="main_nav-item main_nav_news">
          <WarningIcon />
          <p>Qui Notizie</p>
        </div>
      </RouterLink>

    </nav>
  </div>
</template>

<script>
import HomeIcon from './icons_components/HomeIcon.vue';
import FileIcon from './icons_components/FileIcon.vue';
import SimulatoreIcon from './icons_components/SimulatoreIcon.vue';
import GameIcon from './icons_components/GameIcon.vue';
import MapIcon from './icons_components/MapIcon.vue';
import WarningIcon from './icons_components/WarningIcon.vue';

export default {
  data() {
    return{
      mainLogo: "../assets/icons/main-icon.png",
    }
  },
  components: {
    HomeIcon,
    FileIcon,
    SimulatoreIcon,
    GameIcon,
    MapIcon,
    WarningIcon
  },
  computed: {
    quizRoute(){
      return this.$route.path.substring(0, 5) === "/quiz";
    },
    blogRoute(){
      return this.$route.path.substring(0, 5) === "/blog";
    },
    newsRoute(){
      return this.$route.path.substring(0, 5) === "/news";
    }
  }
}
</script>

<style>
  .main_nav{
    position: fixed;
    top: 150px;
    bottom: 300px;
    left: 0;
    width: 230px;
    height: calc(100% - 300px - 150px);
    background: #F2F2F2;
    z-index: 11;
  }
  nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

  }
  .main_logo{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 230px;
    height: 230px;
  }
  .main_logo img{
    width: 170px;
    height: 170px;
    object-fit: cover;
  }
  .main_nav-item_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main_nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
    margin: 0 0 16px;
    padding: 20px;
    background: #E7E7E7;
    border-radius: 5px;
  }
  .main_nav-item svg{
    width: fit-content;
    height: 77px;
    max-width: 77px;
  }
  .router-link-active.router-link-exact-active .main_nav-item svg path{
    fill: #fff;
  }
  .main_nav-item p{
    max-width: 120px;
    margin: 20px auto 0;
    font-size: 17px;
    line-height: 1.1;
    font-weight: 600px;
    color: #707070;
    text-align: center;
  }
  .router-link-active.router-link-exact-active .main_nav-item p{
    color: #fff;
  }
  .router-link-active.router-link-exact-active .main_nav-item.main_nav_home{
    background: var(--color_main_blue);
  }
  .router-link-active.router-link-exact-active .main_nav-item.main_nav_blog{
    background: #FFBE2D;
  }
  .router-link-active.router-link-exact-active .main_nav-item.main_nav_simulator{
    background: #E587B4;
  }
  .router-link-active.router-link-exact-active .main_nav-item.main_nav_quiz{
    background: #00A3D8;
  }
  .router-link-active.router-link-exact-active .main_nav-item.main_nav_map{
    background: #1E201E;
  }
  .router-link-active.router-link-exact-active .main_nav-item.main_nav_news{
    background: #8CC63E;
  }
</style>