<template>
  <Transition name="modal">
    <div v-if="show">
        <div class="m-mask" @click="$emit('close')"></div>
        <div class="m-container">
            <iframe v-if="splitVideoUrl" :src="splitVideoUrl"></iframe>
        </div>
    </div>
  </Transition>
</template>

<script>
    export default {
        props: {
            show: Boolean,
            slide: Object
        },
        computed: {
            splitVideoUrl() {
                return 'https://www.youtube.com/embed/' + this.slide.video_url.split('/').reverse().slice(0, 1);
            }
        }
    }
</script>

<style scoped>
    .m-mask {
        position: fixed;
        z-index: 106;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        transition: opacity 0.3s ease;
    }
    .m-container {
        position: fixed;
        z-index: 107;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: calc(100% - 80px);
        height: calc(90vw / 16 * 9);
        margin: auto;
        transition: all 0.3s ease;
    }
    .m-container iframe{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .modal-enter-from {
        opacity: 0;
    }
    .modal-leave-to {
        opacity: 0;
    }
    .modal-enter-from .modal-container,
    .modal-leave-to .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>