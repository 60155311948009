<template>
    <div class="result-page page-padding">
        <h1>Scopri quanto ne sai</h1>
        <div class="result-img_block">
            <div class="result-img_wrapper">
                <div class="result-img">
                    <img :src="flagIcon" alt="Scopri quanto ne sai">
                </div>
            </div>
            <h2>Hai risposto correttamente<br>a <span>{{ correctAnswersSum }}</span> domande su 5.</h2>
        </div>
        <RouterLink to="/quiz/quiz-game">
            <button class="main-butt">Gioca con la luminaria</button>
        </RouterLink>
    </div>
</template>

<script>
    import { storeToRefs } from 'pinia'
    import { useStepsStore } from './../../stores/StepsStore.js';

    export default {
        data() {
            return{
                flagIcon: "../src/assets/icons/step-flag.svg"
            }
        },
        mounted() {
            this.loadingPage = false
        },
        setup() {
            const { loadingPage} = storeToRefs(useStepsStore())

            const { correctAnswersSum } = useStepsStore();

            return { correctAnswersSum, loadingPage };  
        }
    }
</script>

<style scoped>
    .result-img_block{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 300px 0 0;
    }
    .result-img_wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        margin: 0 auto 100px;
        border: 10px solid var(--color_main_blue);
        border-radius: 50%;
    }
    .result-img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background: var(--color_main_blue);
        border-radius: 50%;
    }
    .result-img img{
        width: 50px;
        height: 50px;
    }
    .result-img_block h2{
        text-align: center;
        line-height: 54px;
    }
    .result-img_block h2 span{
        font-size: 48px;
        font-weight: 800;
    }
    .main-butt{
        width: 540px;
        height: 100px;
        margin: 500px auto 0;
        font-size: 36px;
    }
</style>