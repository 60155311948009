<template>
    <div class="simulator-page page-padding">
        <h1>Gioca con il simulatore</h1>

        <div v-if="simulator.length > 0" class="simulator-page_inner">
            <div v-for="data in simulator" :key="data.id">
                <div class="simulator-page_img-wrapper" style="height: 300;">
                    <img :src="data.image_url" alt="simulator" style="height: 300;">
                </div>
                <div v-html="data.page_desc" class="simulator-page_text"></div>
            </div>
        </div>
<!--             
        <div class="stroke-grey"></div>

        <div class="simulator_adress">
            <h2>Dove giocare a Lecce</h2>
            <p><span>18 marzo</span>, dalle 10 alle 12, al Liceo Palmieri</p>
            <p><span>20 aprile</span>, dalle 09 alle 13, al Liceo Banzi Bazoli</p>
            <p><span>21 aprile</span>, dalle 09 alle 18, presso la sede AC</p>
        </div> -->
    </div>
</template>

<script>
import axios from "axios";
import { useStepsStore } from '../stores/StepsStore.js';
import { storeToRefs } from 'pinia'

export default {
    data() {
        return{
            simulator: [],
            driveImg: "./../assets/icons/simulatore.svg"
        }
    },
    mounted(){
        axios
            .get('https://bo.pitstoplecce.it/index.php?r=page/page&id=1')
            .then(response => {
                this.simulator = response.data;
            })
            .catch((errors) => console.log(errors));
        this.loadingPage = false
    },
    setup() {
      const { loadingPage} = storeToRefs(useStepsStore())
      
      return {
        loadingPage
      };  
    }
}

</script>

<style scoped>
    .simulator-page_inner{
        margin: 0 0 40px;
    }
    .simulator-page_img-wrapper{
        width: 100%;
        height: 490px;
        margin: 85px 0;
    }
    .simulator-page_inner img{
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        object-fit: cover;
        border-radius: 20px;
    }
    .simulator-page_inner ::v-deep p{
        margin: 0 0 15px;
        font-size: 24px;
        color: var(--color_grey-text);
    }
    .simulator_adress{
        margin: 40px 0 0;
    }
    .simulator-page h2{
        margin: 0 0 40px;
        font-weight: 800;
        color: var(--color_main_blue);
    }
    .simulator_adress p{
        margin: 0 0 10px;
        font-size: 30px;
        font-weight: 500;
        color: var(--color_grey-text);
    }
    .simulator_adress p span{
        font-size: 30px;
        font-weight: 700;
        color: #707070;
    }
</style>