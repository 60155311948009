<template>
    <div class="page-padding">
        <h1>Ultime da PitStop</h1>
        <div v-if="article">
            <div class="blog-detal">
                <h2 class="blog-detal_title">{{ article.news_title }}</h2>
                <div class="blog-detal_data-wrapper">
                    <span class="blog-detal_data">Date start: {{ article.date_start }}</span>
                    <span class="blog-detal_data">Date end: {{ article.date_end }}</span>
                </div>
            </div>
            <img class="blog-detal_img" :src="article.image_url" :alt="article.news_title"/>
            <div class="container-padding">
                <p class="blog-detal_text" v-html="article.news_desc"></p>
                <div v-if="showVideo">
                    <div class="line-grey"></div>
                    <iframe width="717" height="538" :src="article.video_url" title="Bohren &amp; der Club of Gore - Midnight Radio [FULL ALBUM]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { storeToRefs } from 'pinia'
    import { useBlogStore } from './../../stores/BlogStore.js';
    import { useStepsStore } from './../../stores/StepsStore.js';
    import Loader from './../../components/Loader.vue';
    import ErrorAxios  from './../../components/alerts/ErrorAxios.vue';

    export default {
        props: ['id'],
        data() {
            return {
                article: null,
                blogDetalLogo: "./../../assets/icons/file.svg",
                showVideo: true
            }
        },
        mounted() {
            this.loadingPage = false;

            this.article = this.fetchArticle(this.$route.params.id);

            this.article.video_url = 'https://www.youtube.com/embed/' + this.article.video_url.split("=").reverse()["0"];
            if(this.article.video_url == 'https://www.youtube.com/embed/' + ''){
                this.showVideo = false;
            };
        },
        setup() {
            const { loading, error, currentArticles } = storeToRefs(useBlogStore())

            const { fetchArticles, fetchArticle, timeConverter } = useBlogStore();

            const { loadingPage} = storeToRefs(useStepsStore())

            return { loadingPage, loading, error, fetchArticles, fetchArticle, timeConverter, currentArticles };  
        }
    }
</script>

<style scoped>
    .blog-detal{
        margin: 80px 0 0;
    }
    .blog-detal_data-wrapper{
        margin: 40px 0 30px;
    }
    span.blog-detal_data{
        display: block;
        margin: 0 0 10px;
        font-size: 20px;
    }
    .blog-detal_title{
        font-weight: 700;
        color: var(--color_main_blue);
    }
    .blog-detal_img{
        margin: 30px 0;
        border-radius: 20px;
    }
    .blog-detal_text ::v-deep p{
        margin: 0 0 20px;
        color: var(--color_grey-text);
        font-size: 20px;
        line-height: 24px;
    }
    iframe {
        margin: 30px 0 0;
        width: calc(100%);
        height: calc( (750px) / 16 * 9 );
        border-radius: 20px;
    }
</style>