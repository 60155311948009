<template>
    <div :style="{ backgroundImage: `url(${img})` }" id="splash" @click="handleClick">
        <img :src="logo" />
    </div>
</template>
  
  <script>
  export default {
          setup(){
            function showSplash(){
                document.getElementById('splash').style.display = 'block';
            }
            return {
                showSplash
            }
          },
          methods: {
            handleClick: () => {
                document.getElementById('splash').style.display = 'none';
            },
        },
          mounted() {
              this.showSplash()
          },
          data() {
              return{
                  img: "./../assets/icons/splash-img.webp",
                  logo: "./../assets/icons/splash-logo.svg"
              }
          }
      }
  </script>
  
  <style scored>
    #splash {
        width: 100vw;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        z-index: 12;
        display: none;
    }
    #splash img {
        margin: 140px 180px;
        width: calc(100% - 360px);
    }
  </style>