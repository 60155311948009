<template>
    <div class="quiz-steps_page page-padding">
        <h1>Scopri quanto ne sai</h1>
        <div> 
            <div class="step">
                <div>
                    <Loader v-if="loading" />
                    <ErrorAxios v-if="error" />
                    <QuizStep v-if="questions.length > 0" :question="questions[currentStep]" :numberIcon="numberIcon" :key="currentStep"></QuizStep>
                </div>
            </div>
        </div>
        <div class="steps-buttons">
            <div class="steps-buttons_wrapper">
                <button class="main-butt" @click="prevStep" :disabled="currentStep === 0">Indietro</button>
                <button class="main-butt" @click="nextStep">Avanti</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { useStepsStore } from './../../stores/StepsStore.js';
    import { storeToRefs } from 'pinia'
    import QuizStep from '../../components/quiz-steps_components/QuizStep.vue';
    import Loader from './../../components/Loader.vue';
    import ErrorAxios  from './../../components/alerts/ErrorAxios.vue';

    export default {
        components: {
            QuizStep,
            Loader,
            ErrorAxios
        },
        data() {
            return {
                numberIcon: 1,
                currentStep: 0
            }
        },
        mounted(){
            this.loadingPage = false;
            this.fetchQuestions();
            this.pickeds = {};
            this.correctAnswersSum = 0;
        },
        methods: {
            prevStep() {
                this.numberIcon--;
                this.currentStep--;
            },
            nextStep() {
                if(this.currentStep == this.maxSteps){
                    this.saveResults();
                    this.$router.push({path: '/quiz/quiz-result'});
                    console.log(this.pickeds, 'pickeds');
                    console.log(this.correctAnswersSum, 'correctAnswersSum');
                } else {
                    this.numberIcon++;
                    this.currentStep++;
                }
            }
        },
        setup() {
            const { loadingPage, questions, loading, error, maxSteps, pickeds, correctAnswersSum} = storeToRefs(useStepsStore())

            const {fetchQuestions, saveResults} = useStepsStore();

            return {
                questions,
                loading,
                error,
                maxSteps,
                fetchQuestions,
                saveResults,
                pickeds,
                correctAnswersSum,
                loadingPage
            };  
        }
    }
</script>

<style scoped>
    .steps-buttons{
        position: fixed;
        right: 0;
        bottom: 300px;
        width: calc(100% - 230px);
        background: #FFFFFF;
    }
    .steps-buttons_wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        padding: 20px 0 50px;
    }
    .steps-buttons button{
        width: 300px;
        height: 80px;
        margin: 0 20px;
        font-size: 36px;
    }
    .steps-buttons button:disabled{
        filter: opacity(0.5);
    }
</style>