import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import './assets/styles/main.css'
import './stores/index.js'

import { useStepsStore } from './stores/StepsStore.js';

import { loadScript, removeScript } from './load-script'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.config.globalProperties.$showMessage = function(typeId, message) {
    this.messageText = message;
    this.messageType = typeId;
    document.getElementsByClassName('top-alert')[0].style.marginTop = '180px';
    setTimeout(() => {
        document.getElementsByClassName('top-alert')[0].style.marginTop = '0px';
    }, "6000");
}

app.mount('#app')

const storeS = useStepsStore()

loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyAft07Sbqd1JRQfgOLxLjt4aNgBWqug4sA")//&callback=initialize

router.beforeEach(() => {
  storeS.loadingPage = true
})

router.afterEach((to) => {
  window.scrollTo(0,0);
    if (to.name === 'QuizPage') {
  };
});