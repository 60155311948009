<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
        <defs>
            <clipPath id="clip-path">
            <rect id="Rettangolo_30" data-name="Rettangolo 30" width="32" height="32" fill="#707070"/>
            </clipPath>
        </defs>
        <g id="Raggruppa_19" data-name="Raggruppa 19" transform="translate(0 0)">
            <g id="Raggruppa_18" data-name="Raggruppa 18" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Tracciato_22" data-name="Tracciato 22" d="M31,16.01a1,1,0,0,1-1-1c0-.263-1.668-1.516-2.065-1.912l-1.085-1.086h.032A3.973,3.973,0,0,1,26,9.515v-4.5a1,1,0,0,0-1-1H23a1,1,0,0,0-1,1v.343a.747.747,0,0,1-1.268.538L17.418,2.584a2,2,0,0,0-2.828,0L2.57,14.6a2,2,0,0,0-.585,1.414h.02a2,2,0,0,0,2,2c.953,0,2,1.343,2,3v7a2,2,0,0,0,2,2h3a1,1,0,0,0,1-1v-6.5a2.5,2.5,0,0,1,2.5-2.5h3a2.5,2.5,0,0,1,2.5,2.5V29a1,1,0,0,0,1,1h3a2,2,0,0,0,2-2v-7a1,1,0,0,1,2,0v7a4,4,0,0,1-4,4H21a3,3,0,0,1-3-3v-6a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1v6a3,3,0,0,1-3,3H8a4,4,0,0,1-4-4v-7a1.27,1.27,0,0,0-1-1.124c-.008,0-.011-.008-.02-.011A4,4,0,0,1,.006,16.01v.216a3.973,3.973,0,0,1,1.15-3.043L13.175,1.17a4,4,0,0,1,5.655,0l1.758,1.757A2.486,2.486,0,0,1,22.5,2.02h3a2.5,2.5,0,0,1,2.5,2.5v5a1.993,1.993,0,0,0,.563,1.386l2.287,2.284c.785.784,1.15,1.174,1.15,1.827a1,1,0,0,1-1,1m-2,2a1,1,0,1,1-1,1,1,1,0,0,1,1-1" transform="translate(0 0)" fill="#707070" fill-rule="evenodd"/>
            </g>
        </g>
    </svg>
</template>