<template>
  <StartScreen />
  <div v-if="false" class="if_note-page">
    <NotePage />
  </div>
  <div v-else class="pit-stop" id="pit-stop">
    <HeaderComponent />
    <div class="app-inner">
      <NavComponent />
      <main>
        <Loader v-if="loadingPage"></Loader>
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
        <NoInternet />
      </main>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { useStepsStore } from './stores/StepsStore.js';
import { storeToRefs } from 'pinia'
import NavComponent from './components/NavComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import NoInternet from './components/alerts/NoInternet.vue';
import Loader from "./components/Loader.vue";
import StartScreen from "./components/StartScreen.vue";
import NotePage from "./views/NotePage.vue"

export default {
  components: {
    NavComponent,
    HeaderComponent,
    FooterComponent,
    NoInternet,
    Loader,
    NotePage,
    StartScreen
  },
  setup() {
    const { loadingPage } = storeToRefs(useStepsStore())

    return {
      loadingPage
    };
  }
}

</script>

<style scoped>
.app-inner {
  position: absolute;
  background: #fff;
  width: 100vw;
  height: calc(100vh - 150px - 300px);
  padding: 150px 0 300px;
  overflow-y: scroll;
  z-index: 9;
}
.app-inner main{
  position: absolute;
  width: calc(100vw - 230px);
  padding: 0 0 400px;
  right: 0;
}
* {
  user-select: none;
}
</style>
