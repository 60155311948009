<template>
    <div class="steps-component">
        <img :src="'../src/assets/icons/step' + numberIcon + '.svg'" :alt="numberIcon">
        <h5>{{ question.question }}</h5>
        <form action="">
            <div>
                <div class="radio-item">
                    <input type="radio" @input="savePickedValue" :name="question.question" :id="question.id + '_1'" :value="question.question + question.id + '_1'" :checked="showActiveInput(question.id, 1)"/>
                    <label :for="question.id + '_1'">{{ question.answer_1 }}</label>
                </div>
                <div class="radio-item">
                    <input type="radio" @input="savePickedValue" :name="question.question" :id="question.id + '_2'" :value="question.question + question.id + '_2'" :checked="showActiveInput(question.id, 2)" />
                    <label :for="question.id + '_2'">{{ question.answer_2 }}</label>
                </div>
                <div class="radio-item">
                    <input type="radio" @input="savePickedValue" :name="question.question" :id="question.id + '_3'" :value="question.question + question.id + '_3'" :checked="showActiveInput(question.id, 3)" />
                    <label :for="question.id + '_3'">{{ question.answer_3 }}</label>
                </div>
                <div class="radio-item">
                    <input type="radio" @input="savePickedValue" :name="question.question" :id="question.id + '_4'" :value="question.question + question.id + '_4'" :checked="showActiveInput(question.id, 4)" />
                    <label :for="question.id + '_4'">{{ question.answer_4 }}</label>
                </div>
                <div class="radio-item">
                    <input type="radio" @input="savePickedValue" :name="question.question" :id="question.id + '_5'" :value="question.question + question.id + '_5'" :checked="showActiveInput(question.id, 5)" />
                    <label :for="question.id + '_5'">{{ question.answer_5 }}</label>
                </div>
            </div>
        </form>
    </div>
</template>
  
<script>
    import { useStepsStore } from './../../stores/StepsStore.js';

    export default {
        props: {
            question: Object,
            numberIcon: Number
        },
        setup(){
            const { savePickedValue, showActiveInput } = useStepsStore();

            return { savePickedValue, showActiveInput }
        }
    }
</script>