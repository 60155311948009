<template>
  <div class="map-modal">
    <div class="close-modal" @click="hidePointBar">
        <img :src="closeModal">
    </div>
    <div class="map-modal_block">
        <div class="logo">
            <img :src="mapLogo" alt="logo">
        </div>
        <div class="name">
            <h2 id="mapname"></h2>
            <p id="mapaddress"></p>
            <p class="showhide" id="slideDown" @click="slideDown">Leggi di più</p>
            <p class="showhide" id="slideUp" @click="slideUp">Chiudi</p>
            <div id="pointdesc"></div>
        </div>
        <!-- <div class="arrow" id="mapArrow">
            <img :src="mapArrow" alt="arrow">
            <p><span id="distance"></span></p>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
       
        setup(){
            function slideUp() {
                document.getElementById('slideUp').style.display = "none";
                document.getElementById('slideDown').style.display = "block";
                var element = document.getElementById('pointdesc');
                var height = element.offsetHeight;
                element.style.height = '0px';
                element.style.height = height + 'px';
                element.style.overflow = 'hidden';
                var animation = element.animate([
                    { height: height + 'px' },
                    { height: 0 }
                ], 100);
                animation.onfinish = function() {
                    element.style.display = 'none';
                }
            }
            function slideDown() {
                document.getElementById('slideUp').style.display = "block";
                document.getElementById('slideDown').style.display = "none";
                var element = document.getElementById('pointdesc');
                // var height = element.offsetHeight;
                element.style.overflow = 'hidden';
                element.style.display = 'block';
            }
            function hidePointBar(){
                var animation = document.getElementsByClassName('map-modal_block')[0].animate([
                    { marginBottom: '-1000px' }
                ], 800);
                animation.onfinish = function() {
                    document.getElementsByClassName('map-modal_block')[0].style.marginBottom = "-1000px";
                }
            }
            return {
                hidePointBar,
                slideUp,
                slideDown
            }
        },
        mounted() {
            this.hidePointBar()
        },
        data() {
            return{
                mapArrow: "./../assets/icons/map-arrow.svg",
                mapLogo: "./../assets/icons/newicon.png",
                closeModal: "./../assets/icons/close_modal.svg"
            }
        }
    }
</script>

<style scored>
    .map-modal{
        position: fixed;
        bottom: 230px;
        left: 230px;
        width: calc(100% - 230px);
        height: fit-content;
        margin: 0;
        padding: 0 0 65px;
        z-index: 10;
        background: #FFF;
        backdrop-filter: blur(3px);
        border-radius: 20px 20px 0px 0px;
        transition: 1s;
    }
    .close-modal{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
    }
    .map-modal_block{
        display: flex;
        align-items: flex-start;
        padding: 30px 20px;
    }
    .map-modal_block .logo{
        width: 48px;
    }
    .map-modal_block .name{
        width: calc(100% - 74px);
    }
    .map-modal_block .name p{
        margin: 6px 0 0;
        color: var(--color_grey-text);
    }
    .map-modal_block .arrow{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
    }
    .map-modal_block img{
        width: 26px;
        margin-bottom: 6px;
    }
    .map-modal_block .arrow span{
        font-size: 14px;
        font-weight: 600;
        color: var(--color_main_blue);
    }
    #distance {
        white-space: nowrap;
    }
    .map-modal_block h2 {
        font-size: 22px;
    }
    #mapaddress {
        font-size: 16px;
    }
    .map-modal_block .name .showhide {
        font-weight: 700;
        font-size: 16px;
        color: var(--color_main_blue);
        padding: 10px 0 5px;
    }
    #pointdesc {
        border-top: 1px solid #eeeeee;
        margin-top: 5px;
        padding: 4px 0;
        display: none;
    }
    #pointdesc * {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
    }
    #slideUp {
        display: none;
    }
</style>