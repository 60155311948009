<template>
  <div class="error">
    <h3>ERROR</h3>
    <p>Sorry, something go wrong...</p>
  </div>
</template>

<style scoped>
    .error{
        padding: 40px 26px;
        text-align: center;
    }
    .error h3{
        margin: 0 auto 5px;
        color: var(--color_main-red);
    }
</style>