<template>
    <div class="note-page">
        <div class="note-page_logo">
            <router-link to="/home">
                <img :src="logoImg" alt="logo">
            </router-link>
        </div>
        <div class="note-page_emblems">
            <div>
                <img :src="mark3" alt="Città di Lecce">
                <p>Città di Lecce</p>
            </div>
            <div>
                <img :src="mark2" alt="Dipartimento per le politiche antidroga">
                <p>Dipartimento per le politiche antidroga</p>
                <p><span>Presidenza del Consiglio dei Ministri</span></p>
            </div>
            <div>
                <img :src="mark1" alt="Automobile Club Lecce">
                <p>Automobile Club Lecce</p>
            </div>
        </div>
        <div class="note-page_text">
            <div class="note-img">
                <div class="note-img_wrapper">
                    <img :src="note" alt="?">
                </div>
            </div>
            <div class="page-title">
                <h1>Cosa c’è da sapere</h1>
            </div>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi, in, libero iusto numquam temporibus ipsam unde consectetur non veniam illum natus! Iste quibusdam nobis qui consequuntur sed, voluptatibus vel architecto.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus aspernatur molestiae quaerat autem recusandae veritatis officia repudiandae maiores possimus nulla. Odio labore tempore dolore quasi aliquam necessitatibus qui quos molestias?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate adipisci fugit eveniet quibusdam amet sint est molestiae, numquam officiis expedita natus dolorum vitae quisquam saepe sequi veritatis possimus doloribus sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis earum tempore repellendus dignissimos ipsum, labore expedita odio commodi dolorum ab voluptate voluptatum facilis itaque corporis autem ipsam, saepe hic eum!</p>
        </div>
        <div class="note-page_continue">
            <a href="#" class="main-butt">Continua</a>
        </div>
    </div>
</template>

<script>
    import { useStepsStore } from '../stores/StepsStore.js';
    import { storeToRefs } from 'pinia';

    export default {
        data() {
            return {
                logoImg: "../assets/icons/logo.svg",
                note: "../assets/icons/note.svg",
                mark1: "../assets/icons/mark1.svg",
                mark2: "../assets/icons/mark2.svg",
                mark3: "../assets/icons/mark3.svg"
            }
        },
        mounted(){
            this.loadingPage = false
        },
        setup() {
            const { loadingPage} = storeToRefs(useStepsStore())
            
            return {
                loadingPage
            };  
        }
    }
</script>

<style scoped>
    .note-page{
        position: absolute;
        width: 100vw;
        height: 100vh;
        padding: 0 0 150px;
        z-index: 110;
        background: #fff;
        overflow-y: scroll;
    }
    .note-page_logo{
        display: flex;
        justify-content: center;
        padding: 75px 0 30px;
    }
    .note-page_logo img{
        width: 134px;
    }
    .note-page_emblems{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 500px;
        margin: 0 auto;
        padding: 0 16px;
    }
    .note-page_emblems div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 32%;
        text-align: center;
    }
    .note-page_emblems img{
        width: fit-content;
        height: 40px;
        margin: 0 auto 10px;
    }
    .note-page_emblems p{
        font-weight: 600;
        line-height: 1.1;
        font-size: 10px;
    }
    .note-page_emblems p span{
        font-size: 10px;
    }
    .note-page_text{
        margin: 32px 16px 50px;
    }
    .note-page_text .note-img{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        border: 4px solid #00A3D8;
    }
    .note-page_text .note-img_wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #00A3D8;
    }
    .note-page_text .note-img_wrapper img{
        width: 20px;
    }
    .note-page_text p{
        margin: 0 0 15px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--color_grey-text);
    }
    .note-page_continue{
        position: fixed;
        bottom: -4px;
        left: 0;
        right: 0;
        width: calc(100% - 50px);
        margin: 0;
        padding: 20px 25px 50px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        background: #fff;
    }
    .note-page_continue a{
        margin: 0 auto;
        width: auto;
        max-width: 500px;
    }
</style>