<template>
  <div class="page-padding">
    <h1>Ultime da PitStop</h1>

    <Loader v-if="loading" />

    <ErrorAxios v-if="error" />

    <div v-if="currentArticles" class="blog">
        <div v-for="article in currentArticles" :key="article.id" class="blog_article-block">
            <div class="blog_article">
                <div class="blog_article-img">
                    <img class="blog_img" :src="article.image_url" :alt="article.news_title">
                </div>
                <div class="blog_article-items">
                    <span class="blog_data">{{ article.date_start }}</span>
                    <router-link :to="{ name: 'BlogDetal', params: {id: article.id} }">
                        <h3 class="blog_title">{{ article.news_title }}</h3>
                    </router-link>
                    <p class="blog_text" v-html="article.news_desc"></p>
                </div>
            </div>
        </div>
        <button @click="loadMoreArticles" v-if="moreArticles" class="show-more_butt">Mostra di più</button>
    </div>
  </div> 
</template>

<script>
    import { storeToRefs } from 'pinia'
    import { useStepsStore } from './../../stores/StepsStore.js';
    import { useBlogStore } from './../../stores/BlogStore.js';
    import Loader from './../../components/Loader.vue';
    import ErrorAxios  from './../../components/alerts/ErrorAxios.vue';

export default {
    components: {
        Loader,
        ErrorAxios
    },
    setup() {
        const { loading, error, currentArticles, moreArticles } = storeToRefs(useBlogStore())

        const { fetchArticles, timeConverter, loadMoreArticles } = useBlogStore();

        const { loadingPage} = storeToRefs(useStepsStore())

        return { loadingPage, loading, error, fetchArticles, timeConverter, loadMoreArticles, currentArticles, moreArticles };  
    },
    mounted(){
        this.fetchArticles();
        this.loadingPage = false;
    }
}
</script>

<style scoped>
    .blog{
        margin: 60px auto 20px;
    }
    .blog_article-block{
        margin: 30px 0 0;
        padding: 0 0 30px;
        border-bottom: 1px solid #707070;
    }
    .blog_article{
        display: flex;
        align-items: flex-start;
    }
    .blog_article-img{
        display: block;
        width: 265px;
        height: 180px;
        object-fit: cover;
    }
    .blog_article-img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    .blog_article-items{
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        margin: 0 0 0 30px;
    }
    .blog_article-block .blog_data{
        margin: 10px 0 0;
        font-size: 20px;
        color: #707070;
    }
    .blog_article-block .blog_title{
        margin: 10px 0;
        color: var(--color_main_blue);
    }
    .blog_article-block .blog_text{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    .blog_article-block .blog_text ::v-deep p{
        font-size: 20px;
        color: #1E201E;
    }
    .show-more_butt{
        display: block;
        margin: 30px 0 0;
        padding: 10px 20px;
        font-size: 24px;
        color: #fff;
        background: var(--color_main_blue);
        border-radius: 5px;
        float: right;
        text-transform: uppercase;
    }
</style>