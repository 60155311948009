<template>
    <div class="pre-bg">
        <div class="preloader" :style="cssVars">
            <span class="rot-1"></span>
            <span class="rot-2"></span>
            <span class="rot-3"></span>
            <span class="rot-4"></span>
            <span class="rot-5"></span>
            <span class="rot-6"></span>
            <span class="rot-7"></span>
            <span class="rot-8"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoaderComponent',
        props: {
            color: {
                type: String,
                default: '#00A3D8',
            },
            scale: {
                type: Number,
                default: 1,
            },
        },
        computed: {
            cssVars() {
                return {
                    '--color': this.color,
                    '--scale': this.scale,
                }
            }
        }
    }
</script>

<style scoped>
    .pre-bg {
        top: 0;
        right: 0;
        width: calc(100% - 230px);
        height: 100%;
        transition: 0.54s;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 101;
    }
    .preloader {
        position: relative;
        width: 62.5px;
        height: 62.5px;
        margin: auto;
        transform: scale(var(--scale));
        z-index: 101;
        
    }
    .preloader > span {
        position: absolute;
        background-color: transparent;
        height: 11px;
        width: 11px;
        border-radius: 12px;
        animation-name: f_fadeG;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }
    .rot-1 {
        left: 0;
        top: 25.5px;
        animation-delay: 0.45s;
    }
    .rot-2 {
        left: 7.5px;
        top: 7.5px;
        animation-delay: 0.6s;
    }
    .rot-3 {
        left:  25.5px;
        top: 0;
        animation-delay: 0.75s;
    }
    .rot-4 {
        right: 7.5px;
        top: 7.5px;
        animation-delay: 0.9s;
    }
    .rot-5 {
        right: 0;
        top: 25.5px;
        animation-delay: 1.05s;
    }
    .rot-6 {
        right: 7.5px;
        bottom: 7.5px;
        animation-delay: 1.2s;
    }
    .rot-7 {
        left: 25.5px;
        bottom: 0;
        animation-delay: 1.35s;
    }
    .rot-8 {
        left: 7.5px;
        bottom: 7.5px;
        animation-delay: 1.5s;
    }
    @keyframes f_fadeG {
        0% {
            background-color: var(--color);
        }
        100% {
            background-color: transparent;
        }
    }
</style>