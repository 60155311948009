<template>
    <div class="quiz-page page-padding">
        <h1>Scopri quanto ne sai</h1>
        <div>
            <p class="quiz-page_text">Rispondi alle domande, poi gioca con le luminarie del Salento.</p>
            <form action="" @submit.prevent="showLoader()">
                <select v-model="userGender" name="sex" id="sex" class="grey-input" required>
                    <option value="" disabled selected hidden>Sesso*</option>
                    <option value="M">Maschio</option>
                    <option value="F">Femmina</option>
                </select>

                <input v-model="userAge" type="text" name="age" id="age" class="grey-input" placeholder="Età*" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" required>
            
                <input v-model="comuneResidenza" type="text" name="adress" id="adress" class="grey-input" placeholder="Comune di residenza*" required />

                <div class="quiz-radio">
                    <p>Hai la patente?</p>
                    <div class="radio-item" @click="showDrivingLicenceType">
                        <input v-model="drivingLicense" value="sì" type="radio" name="driving-licence" id="driving-yes" checked />
                        <label for="driving-yes">Sì</label>
                    </div>
                    <div class="radio-item" @click="hideDrivingLicenceType">
                        <input v-model="drivingLicense" value="no" type="radio" name="driving-licence" id="driving-no" />
                        <label for="driving-no">No</label>
                    </div>
                </div>
                
                <select v-model="typeDrivingLicense" v-if="!hidingLicenceType" name="driving-licence-type"  id="driving-licence-type" class="grey-input" required>
                    <option value="" disabled selected hidden>Quale?*</option>
                    <option value="AM">AM</option>
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="A">A</option>
                    <option value="B1">B1</option>
                    <option value="B">B</option>
                    <option value="B96">B96</option>
                    <option value="BE">BE</option>
                    <option value="C1">C1</option>
                    <option value="C1E">C1E</option>
                    <option value="C">C</option>
                    <option value="CE">CE</option>
                    <option value="D1">D1</option>
                    <option value="D1E">D1E</option>
                    <option value="D">D</option>
                    <option value="DE">DE</option>
                    <option value="KA">KA</option>
                    <option value="KB">KB</option>
                    <option value="CQC Persone">CQC Persone</option>
                    <option value="CQC Merci">CQC Merci</option>
                    <option value="Patentino CFP ADR – tipo B">Patentino CFP ADR – tipo B</option>
                    <option value="Patentino CFP ADR – tipo A">Patentino CFP ADR – tipo A</option>
                    <option value="Patentino CFP ADR – tipo B+esplosivi">Patentino CFP ADR – tipo B+esplosivi</option>
                    <option value="Patentino CFP ADR – tipo B+radioattivi">Patentino CFP ADR – tipo B+radioattivi</option>
                </select>
                <input v-else type="hidden" name="driving-licence-type" id="driving-licence-type" value="">
                
                <button type="submit" class="main-butt" :disabled="loader">Continua</button>
            </form>
            <Loader v-if="loader" />
        </div>
    </div>
</template>

<script>
    import { storeToRefs } from 'pinia';
    import { useStepsStore } from './../../stores/StepsStore.js';
    import Loader from './../../components/Loader.vue';

    export default {
        data() {
            return{
                gameMainIco: "@/../src/assets/icons/game.svg",
                loader: false,
                hidingLicenceType: false,
                userGender: "",
                userAge: "",
                comuneResidenza: "",
                drivingLicense: "sì",
                typeDrivingLicense: ""
            }
        },
        components: {
            Loader
        },
        methods: {
            showLoader(){
                this.loader = true;
                // перемінити потім на реквест
                setTimeout(() => {
                    this.loader = false;
                    this.$router.push({ path: '/quiz/quiz-steps'});
                }, 2000)
                this.saveUserData(this.userGender, this.userAge, this.comuneResidenza, this.drivingLicense, this.typeDrivingLicense);
            },
            showDrivingLicenceType(){
                this.hidingLicenceType = false;
            },
            hideDrivingLicenceType(){
                this.hidingLicenceType = true;
            }
        },
        mounted() {
            this.loadingPage = false
        },
        setup() {
            const { loadingPage} = storeToRefs(useStepsStore());

            const { saveUserData } = useStepsStore();

            return {
                saveUserData,
                loadingPage
            };  

        }
    }
</script>

<style scoped>
    .hide-licence-type{
        display: none;
    }
    .quiz-page_text{
        margin: 85px 0 50px;
        font-size: 34px;
        color: #707070;
    }
    .quiz-radio{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px auto 65px;
    }
    .quiz-radio p{
        margin: 0 15px 0 0;
        font-size: 34px;
    }
    .quiz-radio .radio-item{
        margin: 0 0 0 30px;
    }
    .radio-item label {
        display: flex;
        align-items: center;
    }
    .radio-item label:before {
        top: 0;
    }
    .radio-item input[type=radio]:checked+label:after {
        top: 12px;
    }
    button{
        height: 122px;
        margin: 80px auto 0;
        font-size: 36px;
    }
</style>