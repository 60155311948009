<template>
    <div id="no-int">
        <div class="no-internet">
            <img :src="noInt" >
            <h4>Opss... qualcosa è andato storto</h4>
            <p>Assicurati che ci sia la connessione <br> sul tuo dispositivo.</p>
        </div>
    </div>
    
</template>
<script>
    export default {
        data(){
            return {
                noInt: "./../assets/icons/noint.svg",
            }
        }
    }
</script>
<style scoped>
    #no-int {
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        display: none;
    }
    #no-int.active {
        display: flex;
    }
    .no-internet{
        width: 100%;
        max-width: 400px;
        height: fit-content;
        margin: 0 25px;
        padding: 45px 15px 35px;
        background: #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        text-align: center;
    }
    .no-internet img{
        width: 70px;
        margin: 10px auto;
    }
    .no-internet h4{
        margin: 25px auto 15px;
        color: #fff;
        font-size: 18px;
    }
    .no-internet p{
        color: #fff;
        font-size: 14px;
        line-height: 1.4;
    }
</style>