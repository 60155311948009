<template>
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 53.3 53.3" style="enable-background:new 0 0 53.3 53.3;" xml:space="preserve">
        <path id="Tracciato_30" class="st0" d="M40,18.3c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7l0,0
            C39.2,16.7,40,17.4,40,18.3 M40,53.3H13.3c-3.7,0-6.7-3-6.7-6.7l0,0v-40C6.7,3,9.6,0,13.3,0h12.5C28.1,0,30,1.9,30,4.2v9.2
            c0,1.8,1.5,3.3,3.3,3.3l0,0c0.9,0,1.6,0.8,1.6,1.7c0,0.9-0.7,1.6-1.6,1.6c-3.7,0-6.7-3-6.7-6.7l0,0V5c0-0.9-0.7-1.7-1.7-1.7H13.3
            c-1.8,0-3.3,1.5-3.3,3.3l0,0v40c0,1.8,1.5,3.3,3.3,3.3l0,0H40c1.8,0,3.3-1.5,3.3-3.3l0,0V18.7c0-0.9-0.3-1.7-1-2.4l-9.2-9.2
            c-0.6-0.7-0.6-1.7,0.1-2.4c0.6-0.6,1.6-0.6,2.3,0l9.2,9.2c1.3,1.2,2,2.9,2,4.7v27.9C46.7,50.3,43.7,53.3,40,53.3L40,53.3"/>
        <path id="Tracciato_31" class="st0" d="M21.6,40h10c0.9,0,1.7,0.7,1.7,1.6c0,0.9-0.7,1.7-1.6,1.7h-0.1h-10c-0.9,0-1.7-0.7-1.7-1.6
            S20.6,40,21.6,40L21.6,40 M26.6,53.3c-7.1,0-13.8-2.8-18.8-7.8c-0.7-0.6-0.7-1.7-0.1-2.3c0.6-0.7,1.7-0.7,2.3-0.1l0.1,0.1l0,0
            c9.1,9.1,23.9,9.1,33,0s9.1-23.9,0-33s-23.9-9.1-33,0c-4.4,4.4-6.8,10.3-6.8,16.5c0,1.5,0.2,3,0.5,4.5c0.5,1.3,1.7,2.2,3.1,2.2h13.3
            c0.4,0,0.8-0.2,1-0.5l0,0c2.1-3,6.3-3.7,9.2-1.6c0.6,0.4,1.1,1,1.6,1.6l0,0c0.2,0.3,0.6,0.5,1,0.5h8.5c0.9,0,1.7,0.7,1.7,1.6
            c0,0.9-0.7,1.7-1.6,1.7h-0.1h-8.8c-1.4,0-2.7-0.7-3.4-1.8c-1-1.5-3-2-4.6-1c-0.4,0.3-0.8,0.6-1,1c-0.8,1.1-2.1,1.8-3.4,1.8H6.9
            c-3.1,0-5.7-2.1-6.4-5C-2.3,17.1,7.2,3.2,21.7,0.5s28.4,6.8,31.1,21.3c2.7,14.5-6.8,28.4-21.3,31.1C30,53.2,28.3,53.3,26.6,53.3"/>
        <path id="Tracciato_28" class="st0" d="M48.3,30c-0.9,0-1.7,0.7-1.7,1.7v0.8c0,2.3-1.9,4.2-4.2,4.2c-0.9,0-1.7-0.3-2.4-0.8l0,0
            l-4-3.1c-1.7-1.3-2.6-3.2-2.6-5.3v-3.3c0-2.3-1.9-4.2-4.2-4.2H7.5c-2.3,0-4.2,1.9-4.2,4.2v11.7c0,2.1,1.5,3.8,3.5,4.1c0,0,0,0,0.1,0
            c1.8,0.6,3,2.3,3.1,4.1v1.7c0,0.5,0.4,0.8,0.8,0.8l0,0c0.2,0,0.3-0.1,0.5-0.1l7-5.3c1.1-0.8,2.4-1.3,3.8-1.3h6.2
            c0.9,0,1.7,0.7,1.7,1.6s-0.7,1.7-1.6,1.7h-0.1h-5.8c-0.9,0-1.8,0.3-2.5,0.9l0,0l-6.7,5l0,0c-1.9,1.3-4.5,0.9-5.8-0.9
            c-0.5-0.7-0.8-1.6-0.8-2.4v-1c-0.1-0.9-0.8-1.6-1.7-1.7c0,0,0,0-0.1,0C2,42.3,0,39.7,0,36.7V23.3c0-3.7,3-6.7,6.7-6.7H30
            c3.7,0,6.7,3,6.7,6.7v4.2c0,1.1,0.5,2,1.3,2.7l4,3c0.1,0.1,0.3,0.1,0.5,0.1c0.5,0,0.8-0.4,0.8-0.8l0,0v-1.7c0-2.3,2.1-4.2,3.3-4.2
            c1.6,0,3.3-1.9,3.3-4.2V10.8c0-2.3-1.9-4.2-4.2-4.2H24.2c-2.3,0-4.2,1.9-4.2,4.2v0.8c0,0.9-0.7,1.7-1.6,1.7s-1.7-0.7-1.7-1.6v-0.1
            V10c0-3.7,3-6.7,6.7-6.7h23.3c3.7,0,6.7,3,6.7,6.7v13.3C53.3,27,50.7,30,48.3,30 M35,36.7c0.9,0,1.7,0.7,1.7,1.7S35.9,40,35,40
            s-1.7-0.7-1.7-1.7l0,0C33.3,37.4,34.1,36.7,35,36.7"/>
        <g id="Raggruppa_59" transform="translate(56.295 1070.948)">
            <g>
                <g id="Raggruppa_55" transform="translate(0 0)">
                    <path id="Tracciato_1973" class="st1" d="M-35.9-1042.3h-18.3c-1.1,0-2-0.9-2-2v0v-19.3c0-1.1,0.9-2,2-2h0h24.4c1.1,0,2,0.9,2,2
                        l0,0v13.3c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2v-11.2h-20.4v15.3h16.3c1.1,0,2,0.9,2,2C-33.9-1043.2-34.8-1042.3-35.9-1042.3"/>
                    <path id="Tracciato_1974" class="st1" d="M-5-1061.6h-16.3c-1.1,0-2-0.9-2-2s0.9-2,2-2H-5c1.1,0,2,0.9,2,2S-3.9-1061.6-5-1061.6"
                        />
                    <path id="Tracciato_1975" class="st1" d="M-5-1051.9h-16.3c-1.1,0-2-0.9-2-2s0.9-2,2-2H-5c1.1,0,2,0.9,2,2
                        C-3-1052.8-3.9-1051.9-5-1051.9"/>
                    <path id="Tracciato_1976" class="st1" d="M-5-1042.3h-16.3c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2H-5c1.1,0,2,0.9,2,2
                        C-3-1043.2-3.9-1042.3-5-1042.3"/>
                    <path id="Tracciato_1977" class="st1" d="M-5-1032.6h-49.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2H-5c1.1,0,2,0.9,2,2
                        S-3.9-1032.6-5-1032.6C-5-1032.6-5-1032.6-5-1032.6"/>
                    <path id="Tracciato_1978" class="st1" d="M-5-1022.9h-49.2c-1.1,0-2-0.9-2-2s0.9-2,2-2c0,0,0,0,0,0H-5c1.1,0,2,0.9,2,2
                        C-3-1023.8-3.9-1022.9-5-1022.9C-5-1022.9-5-1022.9-5-1022.9"/>
                    <path id="Tracciato_1979" class="st1" d="M-29.8-1042.3L-29.8-1042.3c-1.2,0-2.1-0.9-2.1-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2.1,2
                        C-27.8-1043.2-28.7-1042.3-29.8-1042.3C-29.8-1042.3-29.8-1042.3-29.8-1042.3"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<style type="text/css">
	.st0{display:none;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st1{fill:#707070;}
</style>