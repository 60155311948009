import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Blog from '../views/Blog/Blog.vue'
import BlogDetal from '../views/Blog/BlogDetal.vue'
import NewsPage from '../views/News/NewsPage.vue'
import NewsDetal from '../views/News/NewsDetal.vue'
import Map from '../views/Map.vue'
import SimulatorPage from '../views/SimulatorPage.vue'
import QuizPage from '../views/Quiz/QuizPage.vue'
import QuizStepsPage from '../views/Quiz/QuizStepsPage.vue'
import QuizGamePage from '../views/Quiz/QuizGamePage.vue'
import QuizResultPage from '../views/Quiz/QuizResultPage.vue'
import Note from '../views/NotePage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/:catchAll(.*)", redirect: '/home' },
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/home',
      name: 'home',
      component: HomeView
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog
    },
    {
      path: '/blog/:id',
      name: 'BlogDetal',
      component: BlogDetal,
      props: true
    },
    {
      path: '/news',
      name: 'NewsPage',
      component: NewsPage
    },
    {
      path: '/news/:id',
      name: 'NewsDetal',
      component: NewsDetal,
      props: true
    },
    {
      path: '/map',
      name: 'Map',
      component: Map
    },
    {
      path: '/simulator',
      name: 'SimulatorPage',
      component: SimulatorPage
    },
    {
      path: '/quiz',
      name: 'QuizPage',
      component:  QuizPage
    },
    {
      path: '/quiz/quiz-steps',
      name: 'QuizStepsPage',
      component: QuizStepsPage,
      props: true
    },
    {
      path: '/quiz/quiz-game',
      name: 'QuizGamePage',
      component: QuizGamePage,
      props: true
    },
    {
      path: '/quiz/quiz-result',
      name: 'QuizResultPage',
      component: QuizResultPage,
      props: true
    },
    {
      path: '/note',
      name: 'Note',
      component: Note,
      props: true
    }
  ]
});

const sessionStorageSetHandler = function (event) {
  setTimeout(() => {
    if (event.key != 'openLink') return;
    router.push({ path: event.value })
  }, 2)
};

document.addEventListener("openLink", sessionStorageSetHandler, false);



let timeoutId;
function mywait(){
  clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      router.push('/home');
      setTimeout(() => {
        document.getElementById('splash').style.display = 'block';
      }, 0)
  }, 120000);
}
document.addEventListener('mousemove', mywait);
document.addEventListener('keydown', mywait);
document.addEventListener('click', mywait);
document.addEventListener('touchstart', mywait);


export default router;