<template>
  <div class="page-padding">
    <div class="main-carousel">
      <carousel :items-to-show="1" :autoplay="0" :wrap-around="true">
        <slide v-for="slide in slides" :key="slide.id">
          <div v-if="slide.video_url !== ''" class="carousel-item">
            <button id="play-video" @click="playVideo">
              <div v-if="carouselVideo">
                <iframe :src="'https://www.youtube.com/embed/' + slide.video_url.split('/').reverse().slice(0, 1)" width="100%" height="490"></iframe>
              </div>
              <div v-else>
                <img :src="play" class="play">
                <img :src="slide.image_url" alt="" width="100%" height="490">
              </div>
            </button>
          </div>
          <!-- <div v-if="slide.video_url !== ''" class="carousel-item">
            <button id="show-modal" @click="showVideoModal = true">
              <img :src="play" class="play" >
              <img :src="slide.image_url" alt="">
            </button>
            <Teleport to="body">
              <VideoModal :show="showVideoModal" :slide="slide" @close="showVideoModal = false"></VideoModal>
            </Teleport>
          </div> -->
          <div v-else class="carousel-item">
            <img :src="slide.image_url" alt="" width="100%" height="490">
            <!-- <img :src="slide.image_url" alt="" @click="this.$showMessage('success', 'Your request has not been sent')"> -->
          </div>
        </slide>
        <template #addons>
          <pagination />
        </template>
      </carousel>
    </div>
    <div class="main-page_block">
      <h1>Perché PitStop</h1>
      <p>Il progetto Pit Stop della Polizia Locale di Lecce intende lanciare verso i giovani un messaggio per spingerli ad assicurarsi di essere nelle condizioni di guidare prima di entrare in macchina.</p>
      <p>La campagna prevede la realizzazione di un’applicazione per smartphone che veicolerà i contenuti della campagna di prevenzione (notizie, video, eventi) e conterrà la lista e la geolocalizzazione del servizio navetta per il rientro sicuro.</p>
      <p>L’applicazione consentirà di segnalare anonimamente alle autorità di prevenzione e repressione (Polizia Municipale, Polizia Stradale) situazioni di potenziale pericolo o trasgressione delle leggi sulla sicurezza e la tutela della salute (persone alla guida in stato di ebrezza, consumazione e spaccio di stupefacenti, altri pericoli per l’incolumità personale, richieste di sostegno psicologico e sanitario).</p>
      <p>Permetterà infine di compilare un questionario-test anonimo per l’auto-valutazione dell’idoneità alla guida.</p>
    </div>
  </div>
</template>

<script>
  // import MainAlert from '../components/alerts/MainAlert.vue';
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide, Pagination } from 'vue3-carousel';
  import VideoModal from '../components/VideoModal.vue';
  import Loader from '../components/Loader.vue'
  import { useStepsStore } from '../stores/StepsStore.js';
  import { storeToRefs } from 'pinia'
  import { inject } from 'vue'

  export default{
    components: {
      Carousel,
      Slide,
      Pagination,
      VideoModal,
      Loader
      // MainAlert
    },
    data() {
      return{
        slides: [],
        showVideoModal: false,
        play: "assets/icons/play.svg",
        loading: true,
        carouselVideo: false
      }
    },
    mounted() {
      // fetch('https://pitstop.dev.consolidati.it/_json/home_slides.json')
      fetch('https://bo.pitstoplecce.it/?r=slide/slides')
      .then(res => res.json())
      .then(data => {this.slides = data;this.loadingPage = false})
      .catch(err => console.log(err.message))
    },
    methods: {
      playVideo(){
        this.carouselVideo = true;
      }
    },
    computed: {
  },
    setup() {
      if(window.location.search != ''){
        localStorage.setItem('newsid', window.location.search.split('news=')[1]);
      }
      
      const { loadingPage} = storeToRefs(useStepsStore())
      
      return {
        loadingPage
      };  
    }
  }
</script>

<style scoped>
  .carousel{
    height: 540px;
    margin: 0 0 50px;
  }
  .carousel-item{
    border-radius: 20px;
  }
  .carousel-item,
  .carousel-item button{
    width: 100%;
  }
  .carousel-item img{
    width: 100%;
    height: 490px;
    object-fit: cover;
    border-radius: 20px;
  }
  .carousel-item iframe{
    width: 100%;
    height: 490px;
    border-radius: 20px;
  }
  .carousel-item .play {
    width: 100px;
    height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    object-fit: fill;
  }
  .main-page_block h1{
    margin: 0 0 30px;
  }
  .main-page_block p{
    font-size: 30px;
    margin: 0 0 20px;
  }
</style>