import { defineStore } from 'pinia';
import axios from "axios";

export const useNewsStore = defineStore('newsStore', {
    state: () => ({
        loading: false,
        error: null,
        articles: [],
        moreArticles: false,
        perPage: 20,
        currentArticles: []
    }),
    actions: {
        async fetchArticles() {
            this.articles = [];
            this.loading = true;
            
            axios
                .get('https://bo.pitstoplecce.it/?r=news/newss&location_id='+localStorage.getItem('newsid'))
                .then(response => {
                    this.articles = response.data;
                    this.loadMoreArticles();
                })
                .catch((error) => this.error = error)
                .finally(() => this.loading = false);
        },
        fetchArticle(id){
            return this.articles.filter(a => a.id == id)[0];
        },      
        loadMoreArticles(){
            this.moreArticles = false;
            if(this.currentArticles.length < this.articles.length){
                this.currentArticles = this.articles.slice(0, this.perPage + this.currentArticles.length);
                if(this.currentArticles.length < this.articles.length){
                    this.moreArticles = true;
                } 
            }
        },
        timeConverter(UNIX_timestamp){
            let a = new Date(UNIX_timestamp * 1000);
            let year = a.getFullYear();
            let month = a.getMonth();
            if(month < 10){
                month = '0'+month;
            }
            let date = a.getDate();
            if(date < 10){
                date = '0'+date;
            }
            let time = date + '/' + month + '/' + year ;
            return time;
        }
    }
});