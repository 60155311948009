<template>
  <div class="page start">
    <div class="">
      <img :src="startGame" alt="" class="game-img">
      <h2>Un ultimo sforzo...</h2>
      <p>Testa i tuoi riflessi giocando con una luminaria del Salento.</p>
      <p>Premi <img :src="lampadina" class="lampadina" alt=""> <br> quando si accende la lampadina.</p>
    </div>
    <button class="main-butt" @click="start">START</button>
  </div>

  <div class="page restart">
    <div>
      <img :src="startGame" class="game-img">
      <div v-if="finishGame" class="gameResult">
        <p v-for="(res, i) in results.message" :class="{ badresult: res >= 1 }">
          Test {{ i+1 }}: <span><strong>{{ res }}</strong> sec</span>
        </p>
      </div>
      <p id="good" v-if="!mybad" class="shw"> <img :src="goodresult"> <span>I tuoi riflessi sono ok.</span> </p>
      <p id="bad" v-if="mybad" class="shw"> <img :src="badresult"> <span>C'è qualche problema con i tuoi riflessi.</span> </p>
      <router-link to="/home" class="go-home">
        <button class="main-butt" @click="pushData">Esci dal gioco</button>
      </router-link>
    </div>
  </div>

  <div class="page game">
    <div id="gameframe" class="bg0" :style="styleObject"></div>
    <button id="push" class="game-butt" @click="pushClick">
      <img :src="pushImg">
    </button>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { reactive, ref } from 'vue';
import { useStepsStore } from './../../stores/StepsStore.js';

export default {
  data() {
    return {
      gameImg: "../src/assets/icons/luminarie-pitstop.png",
      startGame: "../src/assets/icons/startgame.svg",
      lampadina: "../src/assets/icons/lampadina.svg",
      pushImg: "../src/assets/icons/startImg.svg",
      badresult: "../src/assets/icons/badresult.svg",
      goodresult: "../src/assets/icons/goodresult.svg"
    }
  },
  computed: {
    styleObject() {
      return {
        backgroundImage: `url(${this.gameImg})`
      }
    }
  },
  mounted() {
    this.loadingPage = false
  },
  setup() {
    var clickedTime; 
    var createdTime; 
    var number;
    var clicks = 0;
    var goodres = 5;
    var alltime = 0;
    const resar = [];
    const finishGame = ref(false);
    const mybad = ref(false);
    const results = reactive({
      message: []
    });

    function changePicture() {
      let frame = document.getElementById('gameframe');
      setTimeout(function(){
        console.log(Math.round( Math.random() * 15 + 1));
        number = Math.round( Math.random() * 15 + 1);
        for (let i = frame.classList.length - 1; i >= 0; i--) {
            const className = frame.classList[i];
            if (className.startsWith('bg')) {
                frame.classList.remove(className);
            }
        }
        frame.classList.add("bg"+number);
        createdTime = Date.now();
        document.getElementById('push').style.display = "block";
      }, Math.random() * 1500 + 1000);
    }

    function pushClick() {
      let frame = document.getElementById('gameframe');
      clickedTime = Date.now();

      console.log('clicks = ' + clicks + '; time = ' + alltime);
      for (let i = frame.classList.length - 1; i >= 0; i--) {
          const className = frame.classList[i];
          if (className.startsWith('bg')) {
              frame.classList.remove(className);
          }
      }

      frame.classList.add("bg0");
      document.getElementById('push').style.display = "none";
      changePicture();
      clicks = clicks + 1;
      alltime = alltime + ((clickedTime - createdTime) / 1000);
      resar.push((clickedTime - createdTime) / 1000);
      console.log(clickedTime - createdTime + ' r');
      if(clickedTime - createdTime < 1000){
        navigator.vibrate(80);
      }else{
        navigator.vibrate(400);
      }
      

      if (clicks >= 5) {
        console.log(resar)
        document.getElementsByClassName('restart')[0].style.display = "flex";
        finishGame.value = true;
        for (var i = 0; i < 5; i++) {
          let j = i+1;
          if(resar[i] > 1) {
            mybad.value = true;
            goodres = goodres - 1;
          }
        }
        results.message = resar.slice(0, 5);
        document.getElementsByClassName('shw')[0].style.display = 'flex';
        console.log('goodres' + goodres);
        saveUserResult(goodres);
      }
      
    }

    function start() {
      document.getElementById('push').style.display = "none";
      document.getElementsByClassName('start')[0].style.display = "none";
      changePicture();
    }

    function pushData() {
      saveSurvey();
    }

    const { loadingPage} = storeToRefs(useStepsStore());

    const { saveUserResult, saveSurvey } = useStepsStore();

    return {
      results,
      finishGame,
      mybad,
      pushClick,
      start,
      pushData,
      saveUserResult,
      saveSurvey,
      loadingPage
    }
  }
}
</script>


<style scoped>
.page {
  position: fixed;
  right: 0;
  top: -200px;
  width: calc(100vw - 230px);
  height: 100vh;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.page h2 {
  margin: 80px auto 50px;
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
}
.page p {
  line-height: 36px;
  font-size: 28px;
  margin: 0 auto 50px;
  max-width: 500px;
}
.game-img{
  width: 500px;
}
#arrresults p {
  line-height: 36px;
  font-size: 28px;
  margin: 20px 0;
}
.start {
  z-index: 2;
}
.start div, .restart div {
  padding: 30px;
}
.restart div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.restart {
  z-index: 3;
  display: none;
}
#good {
  color: #3AE735;
}
#bad {
  color: #E51A2F;
}
#good, #bad {
  display: flex;
  align-items: center;
}
#good img, #bad img {
  width: 40px;
  margin-right: 8px;
}
.gameResult {
  width: 500px;
  margin: 50px 0 0;
}
.gameResult p {
  line-height: 36px;
  font-size: 28px;
  text-align: left;
  margin-bottom: 5px;
}
.gameResult p span {
  line-height: 36px;
  font-size: 28px;
  color: #3AE735;
}
.gameResult .badresult span {
  color: #E51A2F;
}
#arrresults .goodresult span,
#good span, #bad span {
  font-weight: 700;
  line-height: 36px;
  font-size: 28px;
}

#gameframe {
  width: 800px;
  height: 600px;
  position: fixed;
  /* right: calc(50vw - 400px); */
  top: 100px;
}
#push {
  position: fixed;
  background: transparent;
  bottom: 500px;
}
.game-butt img{
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-bottom: -50px;
}
.bg0 { background-position: 0px -3600px; }
.bg1 { background-position: -800px -3600px; }
.bg2 { background-position: -1600px -3600px; }
.bg3 { background-position: -2400px -3600px; }
.bg4 { background-position: -3200px -3600px; }
.bg5 { background-position: -800px -2700px; }
.bg6 { background-position: -1600px -2700px; }
.bg7 { background-position: -2400px -2700px; }
.bg8 { background-position: -3200px -2700px; }
.bg9 { background-position: -800px -1800px; }
.bg10 { background-position: -1600px -1800px; }
.bg11 { background-position: -2400px -1800px; }
.bg12 { background-position: -3200px -1800px; }
.bg13 { background-position: -800px -900px; }
.bg14 { background-position: -1600px -900px; }
.bg15 { background-position: -2400px -900px; }
.bg16 { background-position: -3200px -900px; }
.lampadina {
  width: 50px;
  display: inline;
  position: relative;
  bottom: -5px;
  margin-left: 15px;
}
.go-home{
  width: 400px;
  margin: 0 auto;
}
.main-butt {
  position: absolute;
  bottom: 400px;
  width: 400px;
  height: 80px;
  margin: 0 auto;
  font-size: 36px;
}
</style>