<template>
    <div class="running-wrapper">
        <div class="running">
            <div class="ticker-wrapper"></div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
  async mounted() {
    await this.goRSS();
  },
  methods: {
    async goRSS() {
      const currentDate = new Date();
      const unixTime = currentDate.getTime();
      var tw = document.getElementsByClassName('ticker-wrapper')[0];
        if (parseInt(localStorage.getItem('rss-time')) + 1000 * 60 * 60 * 12 > unixTime) {
            let allString = localStorage.getItem('rss');
            for (let i = 0; i < 3; i++) {
                tw.prepend(allString);
            }
            let RSSwidth = tw.offsetWidth;
            document.getElementsByClassName('running')[0].style.setProperty('--data-rss-width', (RSSwidth - window.innerWidth));
        } else {
            localStorage.setItem('rss-time', unixTime);
            let allString = '';
            try {
                const response = await axios.get('https://bo.pitstoplecce.it/?r=feed/feeds');
                const res = response.data;
                res.forEach(function (element) {
                    let string = element.title[0] + ' - ';
                    allString += string;
                });
                for (let i = 0; i < 3; i++) {
                    allString += allString;
                }
                localStorage.setItem('rss', allString);
                tw.prepend(allString);
                let RSSwidth = tw.offsetWidth ;
                document.getElementsByClassName('running')[0].style.setProperty('--data-rss-width', (RSSwidth - window.innerWidth));
            } catch (error) {
                console.error('OOPS!', error);
            }
        }
        },
    },
};
</script>


<style scoped>
    .running-wrapper{
        position: relative;
    }
    .running {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 100%;
        height: 150px;
        font-size: 40px;
        font-weight: 600;
        background: var(--color_main_blue);
        overflow: hidden;
    }
    .ticker-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        white-space: nowrap;
        right: 0;
        animation: ticker  calc(var(--data-rss-width) * 0.005s)  infinite linear forwards;
    }
    @keyframes ticker {
        0% {
            transform: translate(calc(var(--data-rss-width) * 1px), 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }
</style>